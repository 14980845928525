/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react')
const MailingListSubscribeBarState = require('./src/context/MailingListSubscribeBarState')

exports.wrapRootElement = ({ element }) => {
  return (
    <MailingListSubscribeBarState.ProviderWithState>
      {element}
    </MailingListSubscribeBarState.ProviderWithState>
  )
}
