const React = require('react')
const { createContext, useState } = React

const Context = createContext()

function ProviderWithState({ children }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)
  const [contentRect, setContentRect] = useState(null)
  const [triggerContentRect, setTriggerContentRect] = useState(null)
  return (
    <Context.Provider
      value={{
        contentRect: {
          value: contentRect,
          setValue: setContentRect,
        },
        isOpen: {
          value: isOpen,
          setValue: setIsOpen,
        },
        isDismissed: {
          value: isDismissed,
          setValue: setIsDismissed,
        },
        triggerContentRect: {
          value: triggerContentRect,
          setValue: setTriggerContentRect,
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

module.exports = {
  ProviderWithState,
  Context,
}
