exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-collection-tsx": () => import("./../../../src/pages/collection.tsx" /* webpackChunkName: "component---src-pages-collection-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-event-calligraphy-tsx": () => import("./../../../src/pages/event-calligraphy.tsx" /* webpackChunkName: "component---src-pages-event-calligraphy-tsx" */),
  "component---src-pages-giftcards-tsx": () => import("./../../../src/pages/giftcards.tsx" /* webpackChunkName: "component---src-pages-giftcards-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-worksheets-tsx": () => import("./../../../src/pages/worksheets.tsx" /* webpackChunkName: "component---src-pages-worksheets-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-templates-sanity-blog-post-tsx": () => import("./../../../src/templates/SanityBlogPost.tsx" /* webpackChunkName: "component---src-templates-sanity-blog-post-tsx" */),
  "component---src-templates-semi-custom-suite-page-tsx": () => import("./../../../src/templates/SemiCustomSuitePage.tsx" /* webpackChunkName: "component---src-templates-semi-custom-suite-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/ServicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

